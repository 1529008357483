<template>
  <div class="Home">
    <v-header></v-header>
    <div class="Home_box">
      <div class="banner">
        <img src="../assets/Home/banner.png" alt="">
        <div>
          <h1>Trading is Surfing. Enjoy the Crypto Waves.</h1>
          <h2>The first native DEX Aggregator on Linea, focusing on Layer2 ecosystem.</h2>
        </div>
      </div>
      <div class="conter">
        <div class="conter_info">
          <div class="info_test">
            <h3>Trading at the Best Price</h3>
            <div></div>
            <p>SURFI connects liquidity from different sources to enable trades at the best price.</p>
          </div>
          <div class="info_img info-1_img"></div>
        </div>
        <div class="conter_info">
          <div class="info_img info-2_img"></div>
          <div class="info_test">
            <h3>SBT Points and Store</h3>
            <div></div>
            <p>Your trading volume and interactions will be record as SBT Points, according to which you can share the potential incentive by SURFI, Linea and more L2s. The points can also be used mint SURFI NFT, or other rewards.</p>
          </div>
        </div>
        <div class="conter_info">
          <div class="info_test">
            <h3>Trading Campaigns</h3>
            <div></div>
            <p>You can participate the Trading Campaigns by SURFI or other partners for fun and more rewards.</p>
          </div>
          <div class="info_img info-3_img"></div>
        </div>
        <div class="conter_info">
          <div class="info_img info-4_img"></div>
          <div class="info_test">
            <h3>Social Trading</h3>
            <div></div>
            <p>Social Trading will help you easily find Alpha Projects and Smart Money in the ecosystem.</p>
          </div>
        </div>
        <div class="conter_bottom">
          <h1>Surfing the Crypto Waves with us</h1>
          <div>
            <a href="https://swap-linea.surfi.io/swap">Start Trading</a>
          </div>
        </div>
      </div>
    </div>
    <v-footer></v-footer>
  </div>
</template>

<script>

export default {
  name: 'Home',
}
</script>
