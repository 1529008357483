<template>
  <div class="Trade">
    <v-header></v-header>
    <div class="Trade_box"></div>
    <v-footer></v-footer>
  </div>
</template>

<script>

export default {
  name: 'Trade',
}
</script>
