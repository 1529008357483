<template>
  <div class="SBTPoints">
    <v-header></v-header>
    <div class="SBTPoints_box">
      <div class="comingSoonBox">
        <div>
          <img src="../assets/SBTPoints/await.png" alt="">
          <img src="../assets/SBTPoints/comingsoon.png" alt="">
        </div>
      </div>
      <div class="banner">
        <img src="../assets/SBTPoints/banner.png" alt="">
        <div class="bannerConter">
          <div class="banner_left">
            <div :class="mintType ? 'mintInfo mintNFT' : 'mintInfo'">
              <img v-if="!mintType" src="../assets/SBTPoints/nftBase.png" alt="">
              <img v-if="mintType" src="../assets/SBTPoints/nft.png" alt="">
              <div class="mintBtn" v-if="!mintType" @click="mintBtn"> Mint NFT </div>
              <div class="warp"></div>
            </div>
            <p v-if="!mintType">Mint your SBT NFT</p>
          </div>
          <div v-if="mintType" class="banner_right">
            <p class="user"><span>{{userAddress | filtersPc2UsrAddr}}</span> <span @click="enitUserNameBoxShow = true"><img src="../assets/SBTPoints/edit.png" alt="" srcset=""></span></p>
            <p class="data"><span>NO. 1</span> <span>Points 0</span></p>
          </div>
        </div>
      </div>
      <div class="conter">
        <div class="infoanddata">
          <div class="iandd_con">
            <div class="iandd_left">
              <h3>Points</h3>
              <p>SBT Point is the credential of your contribution on SURFl and Linea, as a basis for sharing potential rewards</p>
              <p><span></span><span>The points is accumulated based on your eligible volume, every 100$, 1 points. Each asset may have different weights</span></p>
            </div>
            <div class="centerBorder"></div>
            <ul  class="iandd_right">
              <li>
                <p>My Points</p>
                <p>2</p>
                <p>View</p>
              </li>
              <li>
                <p>Eligible Volume</p>
                <p>123</p>
                <p>View</p>
              </li>
              <li  @click="eligibleAssetsBoxShow = true" style="cursor: pointer;">
                <p>Eligible Assets <span><img  src="../assets/SBTPoints/tips.png" alt=""> <span class="tips">Eligible Volume=Volume * Weight</span></span></p>
                <p class="goView">12</p>
                <p class="goViewBtn">View</p>
                <!--  -->
              </li>
            </ul>
          </div>
        </div>
        <div class="history">
          <div class="his_con">
            <h3>
              History 
              <span></span>
            </h3>
            <ul class="tableTitle">
              <li>Time</li>
              <li>Volume</li>
              <li>Points</li>
              <li>Ranking</li>
              <li><img :class="historyMore ? 'active' : ''" @click="historyMoreBtn" src="../assets/SBTPoints/more.png" alt=""></li>
            </ul>
            <ul class="tableCon" v-for="(item,index) in historynum" :key="index">
              <li>2000-06-26</li>
              <li>$178</li>
              <li>128</li>
              <li>24</li>
              <li></li>
            </ul>
            <div class="pages" v-if="historyMore">
              <ul>
                <li><img src="../assets/SBTPoints/pageBtnNo.png" alt="" srcset=""></li>
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li><img src="../assets/SBTPoints/pageBtn.png" alt="" srcset=""></li>
              </ul>
            </div>
            <!-- <table width="100%">
              <tr>
                <th align="left">Time</th>
                <th>Volume</th>
                <th>Points</th>
                <th>Ranking</th>
                <th align="right"><img src="../assets/SBTPoints/more.png" alt=""></th>
              </tr>
              <tr>
                <td align="left">23</td>
                <td align="center">23</td>
                <td align="center">23</td>
                <td align="center">23</td>
                <td align="right">23</td>
              </tr>
            </table> -->
          </div>
        </div>
        <div class="leaderboard">
          <div class="lea_con">
            <h3>
              <img src="../assets/SBTPoints/Leaderboard.png" alt=""> Leaderboard
            </h3>
            <div class="lea_items">
              <ul class="lea_titles">
                <li :class="leaderIndex == 0 ? 'active' : ''" @click="leaderNav(0)">
                  <span>Today</span><span></span>
                </li>
                <li :class="leaderIndex == 1 ? 'active' : ''" @click="leaderNav(1)">
                  <span>Total</span><span></span>
                </li>
                <li :class="leaderIndex == 2 ? 'active' : ''" @click="leaderNav(2)">
                  <span>Customization</span><span></span>
                </li>
              </ul>
              <div class="lea_select" v-if="leaderIndex == 2">
                <div class="select_con">
                  <div class="con_box">
                    <div class="box_div">
                      <p>
                        <span>{{starTime}}</span><img src="../assets/SBTPoints/time.png" alt="">
                      </p>
                      <el-date-picker
                        v-model="starTime"
                        type="date"
                        @change='getStarTime'
                        value-format="yyyy-MM-dd"
                        placeholder="changeTIme">
                      </el-date-picker>
                    </div>
                    <div class="box_div box_border"></div>
                    <div class="box_div">
                      <p>
                        <span>{{endTime}}</span><img src="../assets/SBTPoints/time.png" alt="">
                      </p>
                      <el-date-picker
                        v-model="endTime"
                        type="date"
                        @change='getEndTime'
                        value-format="yyyy-MM-dd"
                        placeholder="changeTIme">
                      </el-date-picker>
                    </div>
                  </div>
                  <div class="con_btn">Select</div>
                  <p class="con_tip">Please select a custom time period.</p>
                </div>
              </div>
              <div class="lea_lists">
                <div class="lists_0">
                  <ul class="list_title">
                    <li>Ranking</li>
                    <li>User Name (Address)</li>
                    <li>Eligible Volume</li>
                    <li>Points</li>
                  </ul>
                  <ul class="list_list" v-for="(item,index) in LeaderboardList" :key="index">
                    <li><span :id="'metamaskIcon'+item.ranking"></span>{{item.ranking}}</li>
                    <li>{{item.address | filtersPc2UsrAddr}}</li>
                    <li>${{Number(item.volume).toFixed(3)}}</li>
                    <li>${{Number(item.points).toFixed(3)}}</li>
                  </ul>
                  <div class="pages">
                    <ul>
                      <li><img src="../assets/SBTPoints/pageBtnNo.png" alt="" srcset=""></li>
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                      <li>4</li>
                      <li><img src="../assets/SBTPoints/pageBtn.png" alt="" srcset=""></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-footer></v-footer>

    <div class="enitUserName_box" v-if="enitUserNameBoxShow" @click="enitUserNameBoxShow = false">
			<div class="warp" v-on:click.stop>
				<p class="title"><span>Username</span><span @click="enitUserNameBoxShow = false"></span></p>
				<div class="border"></div>
        <div class="ipt">
          <input type="text" v-model="userName" placeholder="Please enter">
        </div>
        <div class="btn">
          <p>Save</p>
        </div>
			</div>
		</div>

    <div class="eligibleAssets_box" v-if="eligibleAssetsBoxShow" @click="eligibleAssetsBoxShow = false">
			<div class="warp" v-on:click.stop>
				<p class="title"><span>Eligible Assets (Eligible Volume=Volume * Weight)</span><span @click="eligibleAssetsBoxShow = false"></span></p>
        <ul class="list_title">
          <li>Coin</li>
          <li>Weight</li>
          <li>Volume</li>
          <li>My Points</li>
        </ul>
				<div class="border"></div>
        <ul class="list_list" v-for="(item,index) in 5" :key="index">
          <li><img src="../assets/SBTPoints/eth.png" alt="" srcset=""> <span>Ethereum</span> <span>ETH</span></li>
          <li>2</li>
          <li>$1233</li>
          <li>99</li>
        </ul>
			</div>
		</div>
  </div>
</template>

<script>
import { metamaskIcon } from '../utils/wallet.js'
export default {
  name: 'SBTPoints',
  filters: {
		filtersPc2UsrAddr(str){
			var firstStr =   str.substr(0,2);
			var lastStr = str.substr(38);
			var middleStr = '***';
			var tempStr = firstStr + middleStr + lastStr;
			return tempStr;
		},
	},
  data() {
		return {
      userAddress:'',
      enitUserNameBoxShow:false,
      eligibleAssetsBoxShow:false,
      mintType:false,
      historyMore:false, historynum:1,
      starTime:'',endTime:'',
      leaderIndex:0,
			LeaderboardList:[
        {
          ranking:1,
          address:'0xB0dA78E803a164dC4EC84eC4F970cAf9c3ACa576',
          volume:123.333123,
          points:33,
        },
        {
          ranking:2,
          address:'0xf01bbF16bDb76Cad04FF1908f6aEC60C0b4f992b',
          volume:123.333123,
          points:33,
        },
        {
          ranking:3,
          address:'0x4Fa85b20E100572351d6dB7d5a74602B8bcC766a',
          volume:123.333123,
          points:33,
        },
        {
          ranking:4,
          address:'0xE2AE4Fd0275721E4EF536B8ca1256A6cE954Df61',
          volume:123.333123,
          points:33,
        },
      ]
		}
	},
  mounted(){
    this.userAddress = localStorage.getItem('userAddress') || ''
    for (let i = 0; i < this.LeaderboardList.length; i++) {
      metamaskIcon(this.LeaderboardList[i].address,40).then(metamaskIconRes => {
				document.getElementById('metamaskIcon'+this.LeaderboardList[i].ranking).appendChild(metamaskIconRes);
			})
    }
    this.starTime = this.getDate()
    this.endTime = this.getDate()
  },
  methods:{
    mintBtn(){
      // if (this.userAddress == '') {
      //   this.$notify({type:'warning',message:'Please connect wallet first!'})
      //   return false
      // }
      this.mintType = true
    },
    historyMoreBtn(){
      this.historyMore = !this.historyMore
      if (this.historyMore) {
        this.historynum = 5
      } else {
        this.historynum = 1
      }
    },
    leaderNav(index){
      this.leaderIndex = index
    },
    getStarTime(){
      console.log(this.starTime)
    },
    getEndTime(){
      console.log(this.endTime)
    },
    getDate() {
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth();
      var date = now.getDate();
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;//
      return defaultDate;
    },
  }
  
}
</script>
